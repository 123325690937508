import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  TRANSITION_COMPLETE,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE_AFTER_DISCUSSION,
  TRANSITION_DISPUTE,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_DECLINE_COUNTER_OFFER,
  TRANSITION_PRO_DISCUSSION,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_COMPLETE_PROPOSAL,
} from '../../util/transaction';
import {
  transactionLineItems,
  updateProfileAPI,
  updateTransactionMetadataAPI,
  sendDailyLogEmailAPI,
  showUserAPI,
} from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
  generateUUID,
} from '../../util/data';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { uploadDocument } from '../../containers/ListingPage/ListingPage.duck';
import { uploadImage } from '../../ducks/googleStorage.duck';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';
const UPLOAD_IMAGE_DELAY = 1000;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const MARK_COMPLETED_REQUEST = 'app/TransactionPage/MARK_COMPLETED_REQUEST';
export const MARK_COMPLETED_SUCCESS = 'app/TransactionPage/MARK_COMPLETED_SUCCESS';
export const MARK_COMPLETED_ERROR = 'app/TransactionPage/MARK_COMPLETED_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const DISPUTE_REQUEST = 'app/TransactionPage/DISPUTE_REQUEST';
export const DISPUTE_SUCCESS = 'app/TransactionPage/DISPUTE_SUCCESS';
export const DISPUTE_ERROR = 'app/TransactionPage/DISPUTE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/TransactionPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/TransactionPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/TransactionPage/UPDATE_PROFILE_ERROR';

export const COUNTER_OFFER_REQUEST = 'app/TransactionPage/COUNTER_OFFER_REQUEST';
export const COUNTER_OFFER_SUCCESS = 'app/TransactionPage/COUNTER_OFFER_SUCCESS';
export const COUNTER_OFFER_ERROR = 'app/TransactionPage/COUNTER_OFFER_ERROR';

export const ACCEPT_COUNTER_OFFER_REQUEST = 'app/TransactionPage/ACCEPT_COUNTER_OFFER_REQUEST';
export const ACCEPT_COUNTER_OFFER_SUCCESS = 'app/TransactionPage/ACCEPT_COUNTER_OFFER_SUCCESS';
export const ACCEPT_COUNTER_OFFER_ERROR = 'app/TransactionPage/ACCEPT_COUNTER_OFFER_ERROR';

export const DECLINE_COUNTER_OFFER_REQUEST = 'app/TransactionPage/DECLINE_COUNTER_OFFER_REQUEST';
export const DECLINE_COUNTER_OFFER_SUCCESS = 'app/TransactionPage/DECLINE_COUNTER_OFFER_SUCCESS';
export const DECLINE_COUNTER_OFFER_ERROR = 'app/TransactionPage/DECLINE_COUNTER_OFFER_ERROR';

export const ACCEPT_PROPOSAL_REQUEST = 'app/TransactionPage/ACCEPT_PROPOSAL_REQUEST';
export const ACCEPT_PROPOSAL_SUCCESS = 'app/Transactionpage/ACCEPT_PROPOSAL_REQUEST';
export const ACCEPT_PROPOSAL_ERROR = 'app/TransactionPage/ACCEPT_PROPOSAL_ERROR';

export const DECLINE_PROPOSAL_REQUEST = 'app/TransactionPage/DECLINE_PROPOSAL_REQUEST';
export const DECLINE_PROPOSAL_SUCCESS = 'app/TransactionPage/DECLINE_PROPOSAL_SUCCESS';
export const DECLINE_PROPOSAL_ERROR = 'app/TransactionPage/DECLINE_PROPOSAL_ERROR';

export const COMPLETE_PROPOSAL_REQUEST = 'app/TransactionPage/COMPLETE_PROPOSAL_REQUEST';
export const COMPLETE_PROPOSAL_SUCCESS = 'app/TransactionPage/COMPLETE_PROPOSAL_SUCCESS';
export const COMPLETE_PROPOSAL_ERROR = 'app/TransactionPage/COMPLETE_PROPOSAL_ERROR';

export const FETCH_CUSTOMER_LISTINGS_REQUEST =
  'app/TransactionPage/FETCH_CUSTOMER_LISTINGS_REQUEST';
export const FETCH_CUSTOMER_LISTINGS_SUCCESS =
  'app/TransactionPage/FETCH_CUSTOMER_LISTINGS_SUCCESS';
export const FETCH_CUSTOMER_LISTINGS_ERROR = 'app/TransactionPage/FETCH_CUSTOMER_LISTINGS_ERROR';

export const CREATE_DAILY_LOG_REQUEST = 'app/TransactionPage/CREATE_DAILY_LOG_REQUEST';
export const CREATE_DAILY_LOG_SUCCESS = 'app/TransactionPage/CREATE_DAILY_LOG_SUCCESS';
export const CREATE_DAILY_LOG_ERROR = 'app/TransactionPage/CREATE_DAILY_LOG_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  markCompletedInProgress: false,
  markCompletedError: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  disputeInProgress: false,
  disputeError: null,
  updateProfileInProgress: false,
  updateProfileError: null,
  counterOfferInProgress: false,
  counterOfferError: null,
  acceptCounterOfferInProgress: false,
  acceptCounterOfferError: null,
  declineCounterOfferInProgress: false,
  declineCounterOfferError: null,
  fetchCustomerListingsInProgress: false,
  fetchCustomerListingsError: null,
  customerListingRefs: [],
  acceptProposalInProgress: false,
  acceptProposalInProgress: null,
  declineProposalInProgress: false,
  declineProposalError: null,
  completePropsalInProgress: false,
  completeProposalInProgress: null,
  createDailyLogInProgress: false,
  createDailyLogError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case MARK_COMPLETED_REQUEST:
      return {
        ...state,
        markCompletedInProgress: true,
      };
    case MARK_COMPLETED_SUCCESS:
      return {
        ...state,
        markCompletedInProgress: false,
        markCompletedError: null,
      };
    case MARK_COMPLETED_ERROR:
      return {
        ...state,
        markCompletedInProgress: false,
        markCompletedError: payload,
      };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case DISPUTE_REQUEST:
      return { ...state, disputeInProgress: true, disputeError: null };
    case DISPUTE_SUCCESS:
      return { ...state, disputeInProgress: false };
    case DISPUTE_ERROR:
      return { ...state, disputeInProgress: false, disputeError: payload };

    case UPDATE_PROFILE_REQUEST:
      return { ...state, updateProfileInProgress: true };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, updateProfileInProgress: false };
    case UPDATE_PROFILE_ERROR:
      return { ...state, updateProfileError: payload };

    case COUNTER_OFFER_REQUEST:
      return {
        ...state,
        counterOfferInProgress: true,
      };
    case COUNTER_OFFER_SUCCESS:
      return {
        ...state,
        counterOfferInProgress: false,
        counterOfferError: null,
      };
    case COUNTER_OFFER_ERROR:
      return {
        ...state,
        counterOfferInProgress: false,
        counterOfferError: payload,
      };

    case ACCEPT_COUNTER_OFFER_REQUEST:
      return {
        ...state,
        acceptCounterOfferInProgress: true,
      };
    case ACCEPT_COUNTER_OFFER_SUCCESS:
      return {
        ...state,
        acceptCounterOfferInProgress: false,
        acceptCounterOfferError: null,
      };
    case ACCEPT_COUNTER_OFFER_ERROR:
      return {
        ...state,
        acceptCounterOfferInProgress: false,
        acceptCounterOfferError: payload,
      };

    case DECLINE_COUNTER_OFFER_REQUEST:
      return {
        ...state,
        declineCounterOfferInProgress: true,
      };
    case DECLINE_COUNTER_OFFER_SUCCESS:
      return {
        ...state,
        declineCounterOfferInProgress: false,
        declineCounterOfferError: null,
      };
    case DECLINE_COUNTER_OFFER_ERROR:
      return {
        ...state,
        declineCounterOfferInProgress: false,
        declineCounterOfferError: payload,
      };

    case FETCH_CUSTOMER_LISTINGS_REQUEST:
      return {
        ...state,
        fetchCustomerListingsInProgress: true,
        fetchCustomerListingsError: null,
      };
    case FETCH_CUSTOMER_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchCustomerListingsInProgress: false,
        fetchCustomerListingsError: null,
        customerListingRefs: payload.listingRefs,
      };
    case FETCH_CUSTOMER_LISTINGS_ERROR:
      return {
        ...state,
        fetchCustomerListingsInProgress: false,
        fetchCustomerListingsError: null,
      };

    case ACCEPT_PROPOSAL_REQUEST:
      return {
        ...state,
        acceptProposalInProgress: true,
        acceptProposalError: null,
      };
    case ACCEPT_PROPOSAL_SUCCESS:
      return {
        ...state,
        acceptProposalInProgress: false,
        acceptProposalError: null,
      };
    case ACCEPT_PROPOSAL_ERROR:
      return {
        ...state,
        acceptProposalInProgress: false,
        acceptProposalError: payload,
      };

    case DECLINE_PROPOSAL_REQUEST:
      return {
        ...state,
        declineProposalInProgress: true,
        declineProposalError: null,
      };
    case DECLINE_PROPOSAL_SUCCESS:
      return {
        ...state,
        declineProposalInProgress: false,
        declineProposalError: null,
      };
    case DECLINE_PROPOSAL_ERROR:
      return {
        ...state,
        declineProposalInProgress: false,
        declineProposalError: null,
      };

    case COMPLETE_PROPOSAL_REQUEST:
      return {
        ...state,
        completePropsoalInProgress: true,
        completePropsoalError: null,
      };
    case COMPLETE_PROPOSAL_SUCCESS:
      return {
        ...state,
        completePropsoalInProgress: false,
        completePropsoalError: null,
      };
    case COMPLETE_PROPOSAL_ERROR:
      return {
        ...state,
        completePropsoalInProgress: false,
        completePropsoalError: null,
      };

    case CREATE_DAILY_LOG_REQUEST:
      return {
        ...state,
        createDailyLogInProgress: true,
        createDailyLogError: null,
      };
    case CREATE_DAILY_LOG_SUCCESS:
      return {
        ...state,
        createDailyLogInProgress: false,
        createDailyLogError: null,
      };
    case CREATE_DAILY_LOG_ERROR:
      return {
        ...state,
        createDailyLogInProgress: false,
        createDailyLogError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const markCompletedRequest = () => ({
  type: MARK_COMPLETED_REQUEST,
});
const markCompletedSuccess = () => ({
  type: MARK_COMPLETED_SUCCESS,
});
const markCompletedError = e => ({
  type: MARK_COMPLETED_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

const disputeRequest = () => ({ type: DISPUTE_REQUEST });
const disputeSuccess = () => ({ type: DISPUTE_SUCCESS });
const disputeError = e => ({ type: DISPUTE_ERROR, error: true, payload: e });

export const updateProfileRequest = () => ({ type: UPDATE_PROFILE_REQUEST });
export const updateProfileSuccess = () => ({ type: UPDATE_PROFILE_SUCCESS });
export const updateProfileError = e => ({ type: UPDATE_PROFILE_ERROR, error: true, payload: e });

export const counterOfferRequest = () => ({ type: COUNTER_OFFER_REQUEST });
export const counterOfferSuccess = () => ({ type: COUNTER_OFFER_SUCCESS });
export const counterOfferError = e => ({ type: COUNTER_OFFER_ERROR, error: true, payload: e });

export const acceptCounterOfferRequest = () => ({ type: ACCEPT_COUNTER_OFFER_REQUEST });
export const acceptCounterOfferSuccess = () => ({ type: ACCEPT_COUNTER_OFFER_SUCCESS });
export const acceptCounterOfferError = e => ({
  type: ACCEPT_COUNTER_OFFER_ERROR,
  error: true,
  payload: e,
});

export const declineCounterOfferRequest = () => ({ type: DECLINE_COUNTER_OFFER_REQUEST });
export const declineCounterOfferSuccess = () => ({ type: DECLINE_COUNTER_OFFER_SUCCESS });
export const declineCounterOfferError = e => ({
  type: DECLINE_COUNTER_OFFER_ERROR,
  error: true,
  payload: e,
});

export const fetchCustomerListingsRequest = () => ({ type: FETCH_CUSTOMER_LISTINGS_REQUEST });
export const fetchCustomerListingsSuccess = listingRefs => ({
  type: FETCH_CUSTOMER_LISTINGS_SUCCESS,
  payload: { listingRefs },
});
export const fetchCustomerListingsError = e => ({
  type: FETCH_CUSTOMER_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const acceptProposalRequest = () => ({
  type: ACCEPT_PROPOSAL_REQUEST,
});
export const acceptProposalSuccess = () => ({
  type: ACCEPT_PROPOSAL_SUCCESS,
});
export const acceptProposalError = e => ({
  type: ACCEPT_PROPOSAL_ERROR,
  error: true,
  payload: e,
});

export const declineProposalRequest = () => ({
  type: DECLINE_PROPOSAL_REQUEST,
});
export const declineProposalSuccess = () => ({
  type: DECLINE_PROPOSAL_SUCCESS,
});
export const declineProposalError = e => ({
  type: DECLINE_PROPOSAL_ERROR,
  error: true,
  payload: e,
});

export const completeProposalRequest = () => ({
  type: COMPLETE_PROPOSAL_REQUEST,
});
export const completeProposalSuccess = () => ({
  type: COMPLETE_PROPOSAL_SUCCESS,
});
export const completeProposalError = e => ({
  type: COMPLETE_PROPOSAL_ERROR,
  error: true,
  payload: e,
});

export const createDailyLogRequest = () => ({
  type: CREATE_DAILY_LOG_REQUEST,
});
export const createDailyLogSuccess = () => ({
  type: CREATE_DAILY_LOG_SUCCESS,
});
export const createDailyLogError = e => ({
  type: CREATE_DAILY_LOG_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const createNewCost = (price, customer, provider) => ({
  attachments: [],
  author: {
    displayName: provider?.attributes?.profile?.displayName || 'N/A',
    id: provider?.id?.uuid || uuidv4(),
    userType: 'client',
  },
  bills: [
    {
      costType: 'labor',
      id: uuidv4(),
      quantity: '1',
      title: '',
      totalPrice: price,
      unitPrice: price,
    },
  ],
  date: new Date().toISOString().substring(0, 10),
  id: uuidv4(),
  title: 'Labor cost',
  description: customer?.attributes?.profile?.displayName
    ? `Accepted quote from ${customer.attributes.profile.displayName}`
    : 'You accepted quote',
});

const addLaborCostsToListing = txId => async (dispatch, getState, sdk) => {
  const transactionResponse = await sdk.transactions.show({
    id: txId,
    include: ['customer', 'provider', 'listing'],
  });
  const listingResponse = await sdk.listings.show({
    id: transactionResponse.data.data.relationships.listing.data.id.uuid,
  });
  const customerResponse = await sdk.users.show({
    id: transactionResponse.data.data.relationships.customer.data.id.uuid,
  });
  const providerResponse = await sdk.users.show({
    id: transactionResponse.data.data.relationships.provider.data.id.uuid,
  });

  const transaction = transactionResponse.data.data;
  const listing = listingResponse.data.data;
  const customer = customerResponse.data.data;
  const provider = providerResponse.data.data;

  const listingCosts = listing.attributes.publicData.costs;
  const laborCost = createNewCost(
    transaction.attributes.protectedData.quote?.price,
    customer,
    provider
  );

  const updatedListingCosts = listingCosts ? [...listingCosts, laborCost] : [laborCost];
  const updateListingResponse = await sdk.ownListings.update({
    id: listing.id.uuid,
    publicData: {
      costs: updatedListingCosts,
    },
  });

  return updateListingResponse.data.data;
};

export const sendQuote = bodyParams => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  return sdk.transactions
    .transition(bodyParams, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendEnquirySuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const sendEnquiry = params => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  const { professionalListingId, budget, document, transactionId } = params;

  const protectedData = {
    quote: {
      professionalListingId,
      price: {
        amount: budget.amount,
        currency: budget.currency,
      },
    },
  };

  // API body params
  const bodyParams = {
    id: transactionId,
    transition: TRANSITION_ENQUIRE_AFTER_DISCUSSION,
    params: {
      protectedData,
    },
  };

  if (document) {
    return dispatch(uploadDocument(document))
      .then(doc => {
        const documentMaybe = doc
          ? {
            document: doc.data,
          }
          : {};

        return documentMaybe;
      })
      .then(documentMaybe => {
        const bodyParamsWithDocument = {
          ...bodyParams,
          params: {
            ...bodyParams.params,
            protectedData: {
              quote: {
                ...bodyParams.params.protectedData.quote,
                ...documentMaybe,
              },
            },
          },
        };

        return dispatch(sendQuote(bodyParamsWithDocument));
      });
  } else {
    return dispatch(sendQuote(bodyParams));
  }
};

export const markCompleted = id => (dispatch, getState, sdk) => {
  dispatch(markCompletedRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_COMPLETE, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(markCompletedSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(markCompletedError(storableError(e)));
      log.error(e, 'mark-completed-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE,
      });
      throw e;
    });
};

export const fetchListing = listingId => (dispatch, getState, sdk) => {
  sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      ...IMAGE_VARIANTS,
    })
    .then(response => dispatch(addMarketplaceEntities(response)))
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      console.log(e);
      throw e;
    });
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];
      const customer = transaction.customer;

      const lastTransition = transaction.attributes.lastTransition;
      const isProDiscussionTransition = lastTransition === TRANSITION_PRO_DISCUSSION;

      const professionalListingId =
        transaction.attributes.protectedData?.quote?.professionalListingId;
      const originalListingId = transaction.attributes.protectedData?.originalListingId;
      if (professionalListingId) {
        dispatch(fetchListing(professionalListingId));
      } else if (originalListingId) {
        dispatch(fetchListing(originalListingId));
      }

      if (isProDiscussionTransition) {
        dispatch(fetchCustomerListings(customer.id));
      }

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());

      const reviewId = response.data.included.find(i => i.type === 'review')?.id;
      return reviewId;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());

      const reviewId = response.data.included.find(i => i.type === 'review')?.id;
      return reviewId;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const acceptSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptSaleSuccess());
      dispatch(addLaborCostsToListing(id));
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const dispute = (id, disputeReason) => (dispatch, getState, sdk) => {
  const params = disputeReason ? { protectedData: { disputeReason } } : {};
  dispatch(disputeRequest());
  return sdk.transactions
    .transition({ id, transition: TRANSITION_DISPUTE, params }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(disputeSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(disputeError(storableError(e)));
      log.error(e, 'dispute-failed', {
        txId: id,
        transition: TRANSITION_DISPUTE,
      });
      throw e;
    });
};

export const updateProfile = (userId, review) => (dispatch, getState, sdk) => {
  dispatch(updateProfileRequest());

  const params = { userId, review };
  return axios(updateProfileAPI, { params })
    .then(response => {
      dispatch(updateProfileSuccess());
      return response;
    })
    .catch(e => {
      dispatch(updateProfileError(storableError(e)));
    });
};

export const counterOffer = (id, oldQuote, newQuote) => (dispatch, getState, sdk) => {
  const params = newQuote ? { protectedData: { oldQuote, quote: newQuote } } : {};
  dispatch(counterOfferRequest());
  return sdk.transactions
    .transition({ id, transition: TRANSITION_COUNTER_OFFER, params }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(counterOfferSuccess());
      dispatch(addLaborCostsToListing(id));
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(counterOfferError(storableError(e)));
      log.error(e, 'counter-offer-failed', {
        txId: id,
        transition: TRANSITION_COUNTER_OFFER,
      });
      throw e;
    });
};

export const acceptCounterOffer = id => (dispatch, getState, sdk) => {
  dispatch(acceptCounterOfferRequest());
  const params = { protectedData: { oldQuote: null } };
  return sdk.transactions
    .transition({ id, transition: TRANSITION_ACCEPT_COUNTER_OFFER, params }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptCounterOfferSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptCounterOfferError(storableError(e)));
      log.error(e, 'accept-counter-offer-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT_COUNTER_OFFER,
      });
      throw e;
    });
};

export const declineCounterOffer = (id, oldQuote) => (dispatch, getState, sdk) => {
  const params = oldQuote ? { protectedData: { quote: oldQuote, oldQuote: null } } : {};
  dispatch(declineCounterOfferRequest());
  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE_COUNTER_OFFER, params }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineCounterOfferSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineCounterOfferError(storableError(e)));
      log.error(e, 'decline-counter-offer-failed', {
        txId: id,
        transition: TRANSITION_DECLINE_COUNTER_OFFER,
      });
      throw e;
    });
};

export const acceptProposal = id => (dispatch, getState, sdk) => {
  dispatch(acceptProposalRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_ACCEPT_PROPOSAL, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptProposalSuccess());
      dispatch(addLaborCostsToListing(id));
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptProposalError(storableError(e)));
      log.error(e, 'accept-proposal-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT_PROPOSAL,
      });
      throw e;
    });
};

export const declineProposal = id => (dispatch, getState, sdk) => {
  dispatch(declineProposalRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE_PROPOSAL, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineProposalSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineProposalError(storableError(e)));
      log.error(e, 'decline-proposal-failed', {
        txId: id,
        transition: TRANSITION_DECLINE_PROPOSAL,
      });
      throw e;
    });
};

export const completeProposal = id => (dispatch, getState, sdk) => {
  dispatch(completeProposalRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_COMPLETE_PROPOSAL, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(completeProposalSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(completeProposalError(storableError(e)));
      log.error(e, 'decline-proposal-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE_PROPOSAL,
      });
      throw e;
    });
};

export const fetchCustomerListings = customerId => (dispatch, getState, sdk) => {
  dispatch(fetchCustomerListingsRequest());

  const params = {
    authorId: customerId,
    pub_type: config.listingTypeProject,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listings = response.data.data;
      const listingIds = listings.map(l => l.id);

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCustomerListingsSuccess(listingIds));
    })
    .catch(e => dispatch(fetchCustomerListingsError(storableError(e))));
};

const sendDailyLogEmail = (transaction, logNotes) => (dispatch, getState, sdk) => {
  const txId = transaction.id.uuid;
  const listingTitle = transaction.listing.attributes.title;
  const customerName = transaction.customer.attributes.profile.displayName;
  const provider = transaction.provider;

  return axios.get(showUserAPI, { params: { id: provider.id.uuid } }).then(response => {
    const user = response.data.data.data;
    const providerEmail = user.attributes.email;

    const params = { txId, logNotes, listingTitle, customerName, providerEmail };
    return axios.get(sendDailyLogEmailAPI, { params });
  });
};

export const uploadDailyLogAttachments = images => (dispatch, getState, sdk) => {
  // Create an array of promises without delay
  const promises = images.map(image => dispatch(uploadImage(image)));

  // Wait for all promises to complete and handle errors
  return Promise.all(promises)
};

export const createDailyLog = (txId, payload) => async (dispatch, getState, sdk) => {
  dispatch(createDailyLogRequest());
  try {

    const { attachments, date, ...actionPayload } = payload;

    const state = getState();
    const transactionRef = state.TransactionPage.transactionRef;

    const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
    const transaction = transactions.length > 0 ? transactions[0] : null;

    const promisedAttachments = attachments
      ? await dispatch(uploadDailyLogAttachments(attachments))
      : [];

    const mappedAttachments = promisedAttachments.filter(Boolean).map(a => a.link);

    const existingDailyLogs = transaction.attributes.metadata.dailyLogs;
    const dailyLogs = existingDailyLogs
      ? [
        {
          id: generateUUID(),
          date: new Date(date).toISOString().substring(0, 10),
          attachments: mappedAttachments,
          ...actionPayload,
        },
        ...existingDailyLogs,
      ]
      : [
        {
          id: generateUUID(),
          date: new Date(date).toISOString().substring(0, 10),
          attachments: mappedAttachments,
          ...actionPayload,
        },
      ];

    return axios
      .post(updateTransactionMetadataAPI, {
        id: txId,
        metadata: { dailyLogs },
      })
      .then(response => {
        dispatch(createDailyLogSuccess(response.data));
        dispatch(addMarketplaceEntities(response.data));
        dispatch(sendDailyLogEmail(transaction, actionPayload.notes));

        return response;
      })
      .catch(e => dispatch(createDailyLogError(storableError(e))));
  } catch (e) {
    dispatch(createDailyLogError(storableError(e)))
  }
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};
